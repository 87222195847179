<template>
  <b-card>

    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="SettingsIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Agregar Setting</span>
        </template>
        <setting-add-tab
          class="mt-2 pt-75"
        />
      </b-tab>
      
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import SettingAddTab from './SettingAddTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    SettingAddTab,
  },
  setup() {
    
  },
}
</script>

<style>

</style>
